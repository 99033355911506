import React from 'react';
import Posts from './posts';

export default function Home() {
    return (
        <div class="content">
            <h1 class="title">Home</h1>
            <Posts />
        </div>
    );
}